import React from 'react';
// import sections
import Testimonial from '../components/servicios/CRM';

const CRM = () => {

  return (
    <>
      <Testimonial topDivider />
    </>
  );
}

export default CRM;