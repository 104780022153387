import React from 'react';
// import sections
import Testimonial from '../components/servicios/RedesSociales';

const RedesSociales = () => {

  return (
    <>
     <Testimonial topDivider />
    </>
  );
}

export default RedesSociales;

