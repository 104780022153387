import React from 'react';
// import sections
import Testimonial from '../components/servicios/ComercioElectronico';

const ComercioElectronico = () => {

  return (
    <>
        <Testimonial topDivider />
      
    </>
  );
}

export default ComercioElectronico;



