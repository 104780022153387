import React from 'react';
// import sections
import Testimonial from '../components/servicios/ERP';
const ERP = () => {

  return (
    <>
    <Testimonial topDivider />
  </>
  );
}

export default ERP;