import React from 'react';
// import sections
import Testimonial from '../components/servicios/Marketplace';

const Marketplace = () => {

  return (
    <>
        <Testimonial topDivider />
      
    </>
  );
}

export default Marketplace;



